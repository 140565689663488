import React from "react"

const AdditionalInfo = () => {
  return (
    <div className="icon-item big-icon black-icon white-bg">
      <i className="fa fa-info"></i>
    </div>
  )
}

export default AdditionalInfo
